import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Material Design Lite App Template | Uizard"
    category="Tablet Templates"
    url="/templates/tablet-templates/google-material-design-tablet-app/"
    metaDescription="Our Pixlab tablet app template embodies Material Design. Use Uizard's template to create your own material design lite tablet app."
    description="
    h2:A tablet app design template using Google Material Design
    <br/>
    PixLab is a fully customizable tablet app design template, with functionality similar to Pinterest or Juxtapost. PixLab's intuitive <a:https://uizard.io/ux-design/>UX design</a> and aesthetic reflects Google's Material Design, an adaptable open-source design system that supports the best practices of user interface design.
    <br/>
    h3:Developing a tablet app prototype with Material Design
    <br/>
    If you're looking to create a tablet app design reflecting the core ethos of Material Design, our easy-to-use PixLab app design template is the perfect choice for you! Uizard has the Material Design theme built right in, empowering you to create the perfect design in minutes.
    <br/>
    h3:PixLab: a tablet app design template that uses Material Design
    <br/>
    PixLab employs the tenets of Material Design, empowering designers and non-designers alike to visualize and ideate rapidly. As with all our <a:https://uizard.io/templates/>UI templates</a>, to use, simply enable the theme and drag-and-drop components and templates using Uizard's intuitive builder. Explore the template now and start making magic with Uizard.
    "
    pages={[
      "Two different views of images for versatile viewing",
      "An individual page featuring a hero photo with metadata visualization",
    ]}
    projectCode="verRa5P9BjhqPpE8Qny9"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Google Material Design Tablet App: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Google Material Design Tablet App: dashboard page"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Google Material Design Tablet App: photo thumbnails page"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Google Material Design Tablet App: image details page"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of Google Material Design Tablet App template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/google-material-design-tablet/GMDWA-tablet-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/google-material-design-tablet/GMDWA-tablet-app-dashboard.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/google-material-design-tablet/GMDWA-tablet-app-big-thumbnails.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/google-material-design-tablet/GMDWA-tablet-app-details.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/google-material-design-tablet/GMDWA-tablet-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
